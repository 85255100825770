<template>
	<div class="list-proposal">
		<div class="proposal-header">
			<div class="for-desk" v-if="windowSize > 768">
				<span class="proposal-count-title">{{ proposalCount }} Proposals</span>
				<div class="proposal-action">
					<div class="search-box">
						<span
							class="select-type"
							@click="
								isShowDropdownSearch = !isShowDropdownSearch
							"
						>
							{{ searchType }}
							<img src="@/assets/img/icon/arrow-drop-down.svg" alt="" />
						</span>
						<input
							type="text"
							:placeholder="'Search ' + searchType"
							class="inp-search"
							v-model="keySearch"
							@keyup.enter="searchProposals()"
						/>
						<img
							src="@/assets/img/icon/search.svg"
							alt=""
							class="ic-search"
							@click="searchProposals()"
						/>
						<ul class="dropdown search" v-if="isShowDropdownSearch">
							<li
								v-for="item in searchTypes"
								:key="item"
								@click="handleSelecteType(item)"
								v-click-outside="outsideSearch"
								:class="{ active: item === searchType }"
							>
								{{ item }}
							</li>
						</ul>
					</div>
					<div class="order-box">
						<button
							class="order"
							@click="isShowDropdownOrder = !isShowDropdownOrder"
						>
							{{ textOrder }}
							<img src="@/assets/img/icon/arrow-drop-down.svg" alt="" />
						</button>
						<ul class="dropdown order" v-if="isShowDropdownOrder">
							<li
								v-for="item in order"
								:key="item.key"
								@click="handleOrder(item)"
								v-click-outside="outsideOrder"
								:class="{
									active: item.key === proposalRequest.order,
								}"
							>
								{{ item.name }}
							</li>
						</ul>
					</div>
					<button class="submit-proposal" @click="createProposal()">
						Submit a proposal
					</button>
				</div>
			</div>
			<div class="for-mobile" v-else>
				<div class="search-box">
					<span
						class="select-type"
						@click="isShowDropdownSearch = !isShowDropdownSearch"
					>
						{{ searchType }}
						<img src="@/assets/img/icon/arrow-drop-down.svg" alt="" />
					</span>
					<input
						type="text"
						:placeholder="'Search ' + searchType"
						class="inp-search"
						v-model="keySearch"
						@keyup.enter="searchProposals()"
					/>
					<img
						src="@/assets/img/icon/search.svg"
						alt=""
						class="ic-search"
						@click="searchProposals()"
					/>
					<ul
						class="dropdown search"
						v-if="isShowDropdownSearch"
						v-click-outside="outsideSearch"
					>
						<li
							v-for="item in searchTypes"
							:key="item"
							@click="handleSelecteType(item)"
							:class="{ active: item === searchType }"
						>
							{{ item }}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="proposal-filter">
			<span
				class="proposal-status"
				v-for="item in status"
				:key="item.key"
				:class="
					proposalRequest.status === item.key
						? item.key + ' ' + 'selected'
						: item.key
				"
				@click="filterByStatus(item.key)"
			>
				{{ item.name }}
			</span>
		</div>
		<div class="for-mobile" v-if="windowSize <= 768">
			<span>{{ proposalCount }} Proposals</span>
			<div class="order-box">
				<button
					class="order"
					@click="isShowDropdownOrder = !isShowDropdownOrder"
				>
					{{ textOrder }}
					<img src="@/assets/img/icon/arrow-drop-down.svg" alt="" />
				</button>
				<ul
					class="dropdown order"
					v-if="isShowDropdownOrder"
					v-click-outside="outsideOrder"
				>
					<li
						v-for="item in order"
						:key="item.key"
						@click="handleOrder(item)"
						:class="{
							active: item.key === proposalRequest.order,
						}"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
		</div>
		<div class="proposal-list" v-if="listProposals.length">
			<div
				class="proposal-item"
				v-for="proposal in listProposals"
				:key="proposal.id"
				@click="gotoDetail(proposal.snapshotId)"
				:id="proposal.snapshotId"
			>
				<div class="proposal-title">
					<img
						style="height: 25px;width: 25px;margin-right: 5px"
						v-if="proposal.snapshotNetwork == '56' || proposal.snapshotNetwork == '97'"
						src="@/assets/img/bsc.svg"
						alt=""
					/>
					<img					
						style="height: 25px;width: 25px;margin-right: 5px"
						v-else-if="proposal.snapshotNetwork == '1' || proposal.snapshotNetwork == '4'"
						src="@/assets/img/ethereum.png"
						alt=""
					/>
					<img				
						style="height: 25px;width: 25px;margin-right: 5px"
						v-else-if="proposal.snapshotNetwork == '137' || proposal.snapshotNetwork == '80001'"
						src="@/assets/img/polygon.png"
						alt=""
					/>
					<h3 style="flex: auto 1 1;">
						{{ proposal.title }}
					</h3>
					<span class="bookmarks">
						<img
							v-if="watchList.indexOf(proposal.snapshotId) !== -1"
							src="@/assets/img/icon/bookmarked.svg"
							alt=""
							@click.stop="removeFromWatch(proposal.snapshotId)"
						/>
						<img
							v-else
							src="@/assets/img/icon/bookmarks.svg"
							alt=""
							@click.stop="
								handleAddToWatchlist(proposal.snapshotId)
							"
						/>
					</span>
				</div>
				<label class="proposal-leading"
					>{{ formatLeadingResult(proposal.status) }}
					<strong>{{ proposal.result }}</strong></label
				>
				<div class="proposal-info">
					<div>
						<span class="proposal-category">
							{{ formatCategory(proposal.type) }}
						</span>
						<span class="proposal-status" :class="proposal.status">
							{{ formatStatus(proposal.status) }}
						</span>
					</div>
					<div class="tags-time">
						<span class="proposal-tags" v-if="proposal.tags">
							{{ formatTags(proposal.tags) }}
						</span>
						<span class="proposal-time">
							<img src="@/assets/img/dao/time.svg" alt="" />
							{{
								formatTimeStamp(
									proposal.title,
									proposal.status,
									proposal.finishAt
								)
							}}</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-proposal">
			<img :src="'/img/icon/file-missing.svg'" alt="" />
			<div class="text1">There’s no proposal yet</div>
			<div class="text2">
				Give us your ideas to make the Dvision World better
			</div>
			<button @click="createProposal()">Write your proposal</button>
		</div>
		<!-- <div class="load-more">
			<button @click="loadMore()">Load More</button>
		</div> -->
		<Pagination
			v-if="proposalCount"
			:align="windowSize > 500 ? 'right' : 'center'"
			:totalRows="proposalCount"
			:perPage="proposalRequest.pageSize"
			:currentPage="proposalRequest.pageIndex"
			@changePage="changePage"
		/>
	</div>
</template>

<script>
import moment from 'moment'
import vClickOutside from 'click-outside-vue3'
import { getProposals, getVotes } from '@/services/proposals.js'
import { mapState } from 'vuex'
import {
	getWatchlist,
	addProposalToWatchlist,
	removeProposalFromWatchlist,
} from '@/services/watchlist.js'
import Pagination from '@/components/Base/Pagination.vue'
import { votingOption, Promotion_Brand, Promotion_Ondoll } from '@/services/config.js';
export default {
	name: 'ListProposals',
	components: {
		Pagination,
	},
	props: {
		timeFrameSelected: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			windowSize: window.innerWidth,
			status: [
				// {
				// 	key: 'all',
				// 	name: 'All of Status',
				// },
				{ key: 'inprogress', name: 'Voting in Progress' },
				{ key: 'ended', name: 'End of Voting' },
				{
					key: 'proceeding',
					name: 'Proceeding',
				},
				{ key: 'complete', name: 'Complete' },
				{ key: 'rejected', name: 'Rejected' },
			],
			proposalRequest: {
				pageIndex: 1,
				pageSize: 20,
				timeFrame: undefined,
				type: undefined,
				status: undefined,
				order: 'DESC',
				title: undefined,
				content: undefined,
				tags: undefined,
			},
			order: [
				{
					key: 'DESC',
					name: 'Lastest',
				},
				{
					key: 'ASC',
					name: 'Oldest',
				},
				{
					key: 'AZ',
					name: 'A - Z',
				},
				{
					key: 'ZA',
					name: 'Z - A',
				},
			],
			keySearch: undefined,
			searchTypes: ['Title', 'Content', 'Tag'],
			searchType: 'Title',
			textOrder: 'Lastest',
			proposalCount: 0,
			listProposals: [],
			isShowDropdownSearch: false,
			isShowDropdownOrder: false,
			watchList: [],
		}
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	async created() {
		this.proposalRequest = {
			pageIndex: this.$route.query.page ? this.$route.query.page : 1,
			type: this.$route.query.category
				? this.$route.query.category
				: undefined,
			status: this.$route.query.status
				? this.$route.query.status
				: undefined,
			order: this.$route.query.order ? this.$route.query.order : 'DESC',
			title: this.$route.query.title
				? this.$route.query.title
				: undefined,
			content: this.$route.query.content
				? this.$route.query.content
				: undefined,
			tags: this.$route.query.tags ? this.$route.query.tags : undefined,
		}
		this.handleRouter()
		this.proposalRequest.pageSize = 20
		await this.fetchProposals()
		this.fetchWatchlist()
	},
	computed: {
		...mapState({
			categorySelected: (state) => state.categorySelected,
			currentUser: (state) => state.userInfo,
		}),
		wallet() {
			return localStorage.getItem('currentNetwork')
		},
	},
	watch: {
		currentUser() {
			this.fetchWatchlist()
			this.fetchProposals()
		},
		'$route.query'(value) {
			if (
				Object.keys(value).length === 0 &&
				this.$route.name === 'Home'
			) {
				this.$store.dispatch('setNewCategory', undefined)
				this.keySearch = ''
				this.searchType = 'Title'
				this.textOrder = 'Lastest'
				this.proposalRequest = {
					pageIndex: this.$route.query.page || 1,
					pageSize: 20,
					timeFrame: undefined,
					type: this.$route.query.category || undefined,
					status: undefined,
					order: 'DESC',
					title: this.$route.query.title || undefined,
					content: this.$route.query.content || undefined,
					tags: this.$route.query.tag || undefined,
				}
				this.fetchProposals()
			}
		},
		wallet() {
			this.proposalRequest.pageIndex = 1
			this.pushNewRouter()
			this.fetchProposals()
		},
		keySearch() {
			if (this.keySearch.startsWith(' ')) {
				this.keySearch = this.keySearch.replace(' ', '')
			}
			this.keySearch = this.keySearch.replaceAll('  ', ' ')
		},
		async categorySelected(key) {
			this.proposalRequest.type = key
			this.pushNewRouter()
			await this.fetchProposals()
		},
		'proposalRequest.status'() {
			this.pushNewRouter()
		},
		'proposalRequest.order'() {
			this.pushNewRouter()
		},
		searchTypes() {
			this.proposalRequest.title = undefined
			this.proposalRequest.content = undefined
			this.proposalRequest.tags = undefined
		},
	},
	methods: {
		isPromotionOndoll(title) {
			return title === Promotion_Ondoll;
		},
		changePageToFirst() {
			this.proposalRequest.pageIndex = 1
		},
		handleRouter() {
			if (this.$route.query.category) {
				this.$store.dispatch(
					'setNewCategory',
					this.$route.query.category
				)
			}
			if (this.$route.query.title) {
				this.searchType = 'Title'
				this.keySearch = this.$route.query.title
			}
			if (this.$route.query.content) {
				this.searchType = 'Content'
				this.keySearch = this.$route.query.content
			}
			if (this.$route.query.tags) {
				this.searchType = 'Tags'
				this.keySearch = this.$route.query.tags
			}
			if (this.$route.query.order) {
				const order = this.$route.query.order
				switch (order) {
					case 'DESC':
						this.textOrder = 'Lastest'
						break
					case 'ASC':
						this.textOrder = 'Oldest'
						break
					case 'AZ':
						this.textOrder = 'A - Z'
						break
					case 'ZA':
						this.textOrder = 'Z - A'
						break

					default:
						break
				}
			}
		},
		pushNewRouter() {
			this.$router.push({
				query: {
					page: this.proposalRequest.pageIndex,
					category: this.proposalRequest.type,
					status: this.proposalRequest.status,
					order: this.proposalRequest.order,
					title: this.proposalRequest.title,
					content: this.proposalRequest.content,
					tags: this.proposalRequest.tags,
				},
			})
		},
		async loadMore() {
			this.proposalRequest.pageSize += 20
			await this.fetchProposals()
		},
		async changePage(page) {
			document.querySelector('.gnb-box-bg').scrollIntoView()
			this.proposalRequest.pageIndex = page
			this.pushNewRouter()
			this.fetchProposals()
		},
		outsideSearch() {
			this.isShowDropdownSearch = false
		},
		outsideOrder() {
			this.isShowDropdownOrder = false
		},
		formatLeadingResult(status) {
			switch (status) {
				case 'inprogress':
				case 'ended':
				case 'proceeding':
					return 'Leading:'
				case 'complete':
				case 'rejected':
					return 'Result:'
				default:
					break
			}
		},
		formatCategory(type) {
			switch (type) {
				case 'map-building':
					return 'Map & Building'
				case 'on-doll':
					return 'On-Doll'
				case 'item':
					return 'Item'
				case 'business':
					return 'Business'
				case 'game':
					return 'Game'
				case 'system':
					return 'System'
				case 'name-ban':
					return 'Name Ban'
				case 'etc':
					return 'Etc.'
				case 'promotion':
					return 'Promotion.'
				default:
					break
			}
		},
		formatStatus(status) {
			switch (status) {
				case 'inprogress':
					return 'Voting in Progress'
				case 'ended':
					return 'End of Voting'
				case 'proceeding':
					return 'Proceeding'
				case 'complete':
					return 'Complete'
				case 'rejected':
					return 'Rejected'
				default:
					break
			}
		},
		formatTags(tags) {
			let result = '#' + tags.replaceAll(' ', ' #')
			if (result.length > 25) {
				return result.slice(0, 25) + '...'
			}
			return result
		},
		async fetchProposals() {
			this.mxShowLoading('inf')
			console.log("proposalRequest : ",  this.proposalRequest);
			await getProposals(this.proposalRequest)
				.then((res) => {
					this.proposalCount = res.count
					this.listProposals = res.listProposals
					console.error({listProposals: res.listProposals});
					this.listProposals.forEach(async (obj) => {
						let resultVote = await getVotes({
							proposals_snapshotId: obj.snapshotId,
							proposals_snapshotNetwork: obj.snapshotNetwork,
							proposals_strategies: obj.strategies,
							proposals_snapshotSpace: obj.snapshotSpace,
							proposals_snapshotProposal: obj.snapshotProposal,
						})
						obj.result = this.textResult(obj, resultVote)
					})
					this.mxCloseLoading()
				})
				.catch((err) => {
					this.mxCloseLoading()
					console.log(err)
				})
		},
		textResult(obj, resultVote) {
			console.log({obj, resultVote});
			let choice = votingOption(obj.type, obj.title)[1];

			const vps = [];
			for(let i = 0; i < choice.length; i++) {
				vps.push(0);
			}

			if (resultVote.data.votes.length > 0) {
				resultVote.data.votes.map((item) => {
					vps[item.choice - 1] += item.vp
				})
			}
			else {
				return 'None'
			}

			let maxValue = Math.max(...vps);
			let maxIndex = vps.indexOf(maxValue);

			return choice[maxIndex];
		},
		formatTimeStamp(title, status, timeStamp) {
			let timeRemain = ''
			if (status === 'inprogress') {
				if(this.isPromotionOndoll(title)) {
					timeRemain = '-';
				} else {
					let eventdate = moment(
						new Date(Number(timeStamp * 1000))
					).valueOf()
					let now = moment().valueOf()

					let timeleft = eventdate - now
					let days = Math.ceil(timeleft / (1000 * 60 * 60 * 24))
					// let days = Math.round(timeleft / (1000 * 60 * 60 * 24))
					// let hours = Math.floor(
					// 	(timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
					// )
					if (days === 1) {
						timeRemain = `Ends in ${days} day`
					} else {
						timeRemain = `Ends in ${days} days`
					}
				}
			} else {
				timeRemain = `Ended ${moment(timeStamp * 1000).format(
					'MMM DD, YYYY'
				)}`
			}

			return timeRemain
		},
		createProposal() {
			this.$router.push({
				path: 'submit',
				query: { backRoute: this.$route.name },
			})
		},
		async fetchWatchlist() {
			const user = this.$store.state.userInfo
			if (user.wallet_addr) {
				let payload = {
					userId: user.wallet_addr,
					pageIndex: 1,
					pageSize: 999,
					status: undefined,
				}
				await getWatchlist(payload).then((res) => {
					if (res.count !== 0) {
						this.watchList = res.listProposals.map(
							(obj) => obj.proposals_snapshotId
						)
					}
				})
			}
		},
		async removeFromWatch(snapshotId) {
			if (this.$store.state.userInfo.wallet_addr) {
				await removeProposalFromWatchlist(
					snapshotId,
					this.$store.state.userInfo.wallet_addr
				).then((res) => {
					if (this.watchList.length === 1) {
						this.watchList = []
					} else {
						this.fetchWatchlist()
					}
				})
			}
		},
		async handleAddToWatchlist(snapshotId) {
			if (this.$store.state.userInfo.wallet_addr) {
				let payload = {
					snapshotId: snapshotId,
					userId: this.$store.state.userInfo.wallet_addr,
				}
				await addProposalToWatchlist(payload).then((res) => {
					this.fetchWatchlist()
				})
			} else {
				this.$router.push({
					name: 'Catalyst',
				})
			}
		},
		gotoDetail(id) {
			this.$router.push({
				name: 'Dao-Detail',
				params: { itemId: id },
			})
		},
		handleSelecteType(type) {
			this.searchType = type
			this.isShowDropdownSearch = false
		},
		async searchProposals() {
			this.proposalRequest.pageIndex = 1
			this.proposalRequest.title = undefined
			this.proposalRequest.content = undefined
			this.proposalRequest.tags = undefined
			switch (this.searchType) {
				case 'Title':
					this.proposalRequest.title = this.keySearch
					break
				case 'Content':
					this.proposalRequest.content = this.keySearch
					break
				case 'Tag':
					this.proposalRequest.tags = this.keySearch
					break
				default:
					break
			}
			this.pushNewRouter()
			await this.fetchProposals()
		},
		async handleOrder(item) {
			this.isShowDropdownOrder = false
			this.textOrder = item.name
			this.proposalRequest.order = item.key
			this.proposalRequest.pageIndex = 1
			await this.fetchProposals()
		},
		async filterByStatus(status) {
			this.proposalRequest.pageIndex = 1
			if (status === this.proposalRequest.status) {
				this.proposalRequest.status = undefined
			} else {
				this.proposalRequest.status = status
			}
			this.pushNewRouter()
			await this.fetchProposals()
		},
	},
}
</script>

<style lang="scss" scoped>
.list-proposal {
	width: calc(100% - 220px);
	padding: 0;
	.proposal-header {
		height: 40px;
		display: flex;
		gap: 10px;
		justify-content: space-between;
		align-items: center;
		.proposal-count-title {
			color: #17141a;
		}
		.proposal-action {
			display: flex;
			gap: 10px;
		}
		.search-box {
			position: relative;
			height: 34px;
			background: #fff;
			border: 1px solid #efeeef;
			border-radius: 4px;
			min-width: 280px;
			.select-type {
				display: inline-block;
				text-align: center;
				padding: 0 15px 0 5px;
				width: 95px;
				cursor: pointer;
				color: #000;
				margin: 0 6px;
				font-size: 14px;
				border-radius: 4px;
				border-right: solid 1px #efeeef;
				img {
					top: -2px;
					left: 5px;
					width: 10px;
				}
			}
			& .inp-search {
				font-family: 'Montserrat';
				height: 32px;
				border-radius: 4px;
				padding: 5px 35px 5px 5px;
				color: #000000;
				margin: 0;
			}
			& .ic-search {
				cursor: pointer;
				position: absolute;
				top: 50%;
				right: 2px;
				transform: translate(-50%, -50%);
			}
		}
		select {
			background: #ffffff;
			border: 1px solid #efeeef;
			border-radius: 4px;
			padding: 5.5px, 16px;
			option {
				font-size: 14px;
				color: #000;
				padding: 5.5px 16px;
			}
		}
	}
	button {
		font-size: 14px;
		padding: 7px 16px;
		border-radius: 4px;
		&.order {
			font-family: 'Montserrat';
			width: 100px;
			color: #000;
			background: #fff;
			border: 1px solid #efeeef;
			img {
				top: -2px;
				left: 5px;
				width: 10px;
			}
		}
	}
	ul.dropdown {
		top: 34px;
		position: absolute;
		z-index: 99;
		height: auto;
		background: #fff;
		border: 1px solid #efeeef;
		border-radius: 4px;
		list-style-type: none;
		&.search {
			left: 0px;
		}
		&.order {
			left: 0px;
			width: 100px;
		}
		li {
			padding: 5.5px 14px;
			font-size: 14px;
			color: #737373;
			&:hover {
				cursor: pointer;
			}
			&.active {
				background: #f9fafb;
				font-weight: 600;
				color: #201360;
			}
		}
	}
	.for-desk,
	.for-mobile {
		display: flex;
		width: 100%;
		font-size: 16px;
		color: #747276;
		align-items: center;
		justify-content: space-between;
		.submit-proposal {
			background: #6c38ef;
			font-size: 14px;
			padding: 5px 15px;
			border-radius: 4px;
			color: #f9fafb;
			background: #6c38ef;
			font-family: 'Montserrat';
			height: 34px;
		}
	}
	.proposal-filter {
		margin-top: 10px;
		display: flex;
		overflow: auto;
		gap: 10px;
		&::-webkit-scrollbar {
			height: 0px;
		}
	}
	.proposal-list {
		.proposal-item {
			background: #ffffff;
			border: 1px solid #eef2ff;
			border-radius: 8px;
			padding: 16px 24px;
			margin: 16px 0;
			&:hover {
				border: 1px solid #6c38ef;
				box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
				transform: translateY(gREm(-5));
				transition: 0.2s;
				cursor: pointer;
			}
			.proposal-title {
				display: flex;
				justify-content: space-between;
				h3 {
					cursor: pointer;
					font-size: 18px;
					font-weight: 700;
					color: #17141a;
					margin-bottom: 12px;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					word-break: break-word;
				}
				.bookmarks {
					width: 20px;
					height: 20px;
					margin-left: 10px;
					cursor: pointer;
					img {
						width: 20px;
						height: 20px;
					}
				}
			}
			.proposal-leading {
				font-size: 14px;
				color: #747276;
				font-weight: 400;
				strong {
					font-weight: 600;
					color: #747276;
				}
			}
			.proposal-info {
				margin-top: 12px;
				display: flex;
				gap: 10px;
				justify-content: space-between;
				align-items: center;
				.proposal-tags {
					display: inline;
					font-size: 14px;
					color: #454348;
					margin-right: 8px;
				}
				.proposal-time {
					img {
						width: 20px;
						height: 20px;
						top: 5px;
					}
					font-size: 14px;
					font-weight: 400;
					color: #747276;
				}
			}
		}
	}
	.no-proposal {
		margin-top: 20px;
		padding: 45px;
		text-align: center;
		background: #ffffff;
		border: 1px solid #eef2ff;
		border-radius: 8px;
		img {
			width: 170px;
			height: auto;
		}
		.text1 {
			margin-top: 20px;
			font-size: 18px;
			font-weight: 600;
			color: #17141a;
		}
		.text2 {
			margin-top: 5px;
			font-size: 14px;
			color: #747276;
		}
		button {
			margin-top: 32px;
			background: #6c38ef;
			color: #f9fafb;
			border-radius: 4px;
			padding: 8px 20px;
			font-family: 'Montserrat';
			font-size: 16px;
		}
	}
	.proposal-category {
		display: inline-block;
		border-radius: 4px;
		border: 1px solid #747276;
		color: #747276;
		padding: 7px 16px;
		font-size: 14px;
		font-weight: 500;
		margin: 10px 10px 0 0;
	}
	.proposal-status {
		display: inline-block;
		border-radius: 4px;
		padding: 7px 16px;
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
		&:hover {
			cursor: pointer;
		}
		&.all {
			color: #747276;
			background: #efeeef;
			border: 1px solid #efeeef;
		}
		&.inprogress {
			color: #3b82f6;
			background: #e2ecfe;
			border: 1px solid #e2ecfe;
			&.selected {
				background: #3b82f6;
			}
		}
		&.ended {
			color: #34d399;
			background: #e1f9f0;
			border: 1px solid #e1f9f0;
			&.selected {
				background: #34d399;
			}
		}
		&.proceeding {
			color: #6c38ef;
			background: #eef2ff;
			border: 1px solid #eef2ff;
			&.selected {
				background: #6c38ef;
			}
		}
		&.complete {
			color: #fcd34d;
			background: #fff9e5;
			border: 1px solid #fff9e5;
			&.selected {
				background: #fcd34d;
			}
		}
		&.rejected {
			color: #f87171;
			background: #feeaea;
			border: 1px solid #feeaea;
			&.selected {
				background: #f87171;
			}
		}
		&.selected {
			color: #fff;
		}
	}
	.load-more {
		text-align: center;
		margin-top: 32px;
		button {
			font-family: 'Montserrat';
			background: #6c38ef;
			color: #f9fafb;
			border-radius: 4px;
			padding: 8px 20px;
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}
@include media-max(800px) {
	.list-proposal {
		width: 100vw;
		padding-left: 20px;
		padding-right: 20px;
	}
}
@include media-max($media_small) {
	// 768
	.list-proposal {
		width: 100%;
		padding: 0 20px;
		.for-desk {
			display: none;
		}
		.for-mobile {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 15px 0;
			.search-box {
				width: 100% !important;
				.inp-search {
					width: calc(100vw - 150px) !important;
				}
			}
		}
		.proposal-action {
			width: 100%;
			justify-content: space-between;
			position: relative !important;
			right: 0 !important;
		}
		.proposal-info {
			flex-direction: column;
			align-items: flex-start !important;
		}
		.proposal-list {
			.proposal-item {
				padding: 15px !important;
			}
		}
	}
}
@include media-max(420px) {
	.tags-time {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
@include media-max(375px) {
	// IPhone X
	.list-proposal {
		.proposal-info {
			.proposal-category {
				padding-left: 8px;
				padding-right: 8px;
			}
			.proposal-status {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
		.proposal-list {
			.proposal-item {
				padding: 15px !important;
			}
		}
	}
}
</style>
