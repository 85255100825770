<template>
	<div class="filter-proposal">
		<div class="filter">
			<div class="filter-title" @click="isExplain = !isExplain">
				<h4 v-if="windowSize <= 800" :class="{ active: isExplain }">
					{{ isExplain ? 'Filter By Category' : textCategory }}
				</h4>
				<h4 v-else :class="{ active: isExplain }">
					Filter By Category
				</h4>

				<span>
					<img
						v-if="isExplain"
						alt=""
						src="@/assets/img/dao/sub.svg"
					/>
					<img v-else alt="" src="@/assets/img/dao/add.svg" />
				</span>
			</div>
			<ul class="list-filter" v-if="isExplain">
				<li class="filter-item" key="all">
					<span class="icon">
						<img src="@/assets/img/icon/all-category.svg" alt="" />
					</span>
					<label
						@click="handleFilter(undefined)"
						:class="{
							active: categorySelected === undefined,
						}"
						>All proposals</label
					>
				</li>
				<li class="filter-item" v-for="item in filters" :key="item.key">
					<span class="icon" v-if="item.icon">
						<img :src="'/img/icon/' + item.icon" alt="" />
					</span>
					<label
						@click="handleFilter(item.key)"
						:class="{
							active: item.key === categorySelected,
						}"
						>{{ item.name }}</label
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'FilterProposal',
	data() {
		return {
			windowSize: window.innerWidth,
			category: this.categorySelected,
			isExplain: window.innerWidth <= 800 ? false : true,
			filters: [
				{
					key: 'map-building',
					name: 'Map & Building',
					icon: 'map-marker.svg',
				},
				{
					key: 'on-doll',
					name: 'On - Doll',
					icon: 'on-doll.svg',
				},
				{ key: 'item', name: 'Item', icon: 'item.svg' },
				{
					key: 'game',
					name: 'Game',
					icon: 'game-controller.svg',
				},
				{
					key: 'business',
					name: 'Business',
					icon: 'business-center-sharp.svg',
				},
				{
					key: 'system',
					name: 'System',
					icon: 'file-system.svg',
				},
				{
					key: 'name-ban',
					name: 'Name Ban',
					icon: 'cancel.svg',
				},
				{
					key: 'etc',
					name: 'Etc.',
					icon: 'etc.svg',
				},
				{
					key: 'promotion',
					name: 'Promotion',
					icon: 'promotion.svg',
				},
			],
		}
	},
	computed: {
		...mapState({
			categorySelected: (state) => state.categorySelected,
		}),
		textCategory() {
			if (this.categorySelected) {
				return this.filters.find(
					(obj) => obj.key === this.categorySelected
				).name
			}
			return 'All proposals'
		},
	},
	methods: {
		handleFilter(key) {
			this.$emit('changePageToFirst')
			this.$store.dispatch('setNewCategory', key)
			if (this.windowSize <= 800) {
				this.isExplain = !this.isExplain
			}
			document.querySelector('.gnb-box-bg').scrollIntoView()
		},
	},
}
</script>

<style lang="scss" scoped>
.filter-proposal {
	grid-column: span 1;
	min-width: 220px;
	.filter {
		.filter-title {
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			h4 {
				font-size: 16px;
				font-weight: 400;
				color: #454348;
				&.active {
					font-weight: 600;
				}
			}
			span {
				width: 20px;
				height: 20px;
			}
		}
		.list-filter {
			padding-top: 10px;
			list-style-type: none;
			.filter-item {
				display: flex;
				align-content: center;
				margin: 20px 0;
				.icon {
					display: inline-block;
					img {
						width: 25px;
						height: 25px;
						border-radius: 100%;
						display: block;
						margin: auto;
					}
				}
				label {
					cursor: pointer;
					margin-left: 12px;
					font-size: 16px;
					color: #454348;
					top: 5px;
					&.active {
						font-weight: 600;
					}
				}
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
	.filter-proposal {
	}
}

@include media-max($media_medium) {
	// 1024
	.filter-proposal {
	}
}

@include media-max($media_small) {
	// 768
	.filter-proposal {
		background: #fff;
		padding: 2px 12px;
		border: 1px solid #eef2ff;
		border-radius: 4px;
		.list-filter {
			border-top: 1px solid #eef2ff;
		}
	}
}
</style>
