<template>
	<div class="news-weekly" v-show="isShow">
		<div class="left">
			<img src="../../assets/img/dao/ic-letter.svg" alt="" />
			<div class="content">
				<div class="title">Stay in the loop</div>
				<div class="desc">
					The DAO weekly newsletter includes updates regarding
					proposals
				</div>
			</div>
		</div>
		<button class="btn-subcribe">Subscribe</button>
		<img
			class="close"
			src="../../assets/img/dao/close-blue.svg"
			alt=""
			@click="closeSubcribe()"
		/>
	</div>
</template>

<script>
export default {
	name: 'NewsWeekly',
	components: {},
	data() {
		return {
			isShow: true,
		}
	},
	created() {
		if (JSON.parse(localStorage.getItem('isShowSubscribe')) !== null) {
			this.isShow = JSON.parse(localStorage.getItem('isShowSubscribe'))
		} else {
			localStorage.setItem('isShowSubscribe', this.isShow)
		}
	},
	methods: {
		closeSubcribe() {
			this.isShow = false
			localStorage.setItem('isShowSubscribe', this.isShow)
		},
	},
}
</script>

<style lang="scss" scoped>
.news-weekly {
	grid-column: span 3;
	font-family: Montserrat;
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	max-width: 1180px;
	height: auto;
	padding: 16px 32px 16px 24px;
	background: #eef2ff;
	border-radius: 8px;
	align-items: center;
	& .left {
		display: flex;
		align-items: top;
		gap: 24px;
		& img {
			width: 40px;
			height: 40px;
		}
		& .content {
			& .title {
				color: #3b82f6;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
			}
			& .desc {
				margin-top: 4px;
				color: #454348;
				font-size: 14px;
				line-height: 21px;
			}
		}
	}

	& .btn-subcribe {
		background: #3b82f6;
		color: #ffffff;
		border-radius: 4px;
		padding: gREm(7px) gREm(16px);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		line-height: 18px;
		height: 28px;
		cursor: pointer;
		font-family: 'Montserrat';
		&:hover {
			background: #1c59bc;
		}
	}
	.close {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}
}
</style>
